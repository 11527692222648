export default {
  game: {
    game_id: 1,
    deck: [
      {
        title: "Beast"
      },
      {
        title: "Gem"
      },
      {
        title: "Armour"
      }
    ]
  },
  started: "2020-01-11 12:11",
  players: []
};
